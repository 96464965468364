<template>
  <a
    :href="
      '?f=administration&f2=switchUser&function=switchUserProject&loginAs=' +
      row['c_projectOwnerID'] +
      '&project=' +
      row['c_domain']
    "
    >{{ row[header] }}</a
  >
</template>
<script>
export default {
  props: ["row", "header"],
};
</script>